import Vue from 'vue';
import axios from 'axios';

const axiosUrl = "https://teprint.tedisyon.com/api"

const axiosInstance = axios.create({
  // baseURL: BASE_URL,
  baseURL: axiosUrl,
  },
);
Vue.prototype.$axios = axiosInstance

export { axiosInstance }