<template >
  <div id="app" >

    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

import { axiosInstance } from '../src/helpers/axios';

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  created: function () {
      axiosInstance.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {

          // if you ever get an unauthorized, logout the user
            this.$store.dispatch("auth/logout");
          // you can also redirect to /login if needed !
          }
          
        });
      });
      //this.$store.dispatch('auth/InitAuth');
      

  },
  methods: {
    
  }
};
</script>
<style>
.el-input__inner {
    border: 0.1px solid #ced4da !important;
    border-radius:0.25rem !important;
}
.el-select {
    display: inline-block !important;
    position: relative !important;
    border: 0.1px solid #ced4da !important;
    border-radius:0.25rem !important;
}
#datatable_filter{
    display: none !important;
}
</style>

